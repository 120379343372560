import React from "react"
import Header from "./header"
import Footer from "./footer"
import { BackToTopButton } from "./atom"
import Toc from "./toc"
import TagBox from "./tagbox"


export const PageLayout = ({ children }) => {
  return (
    <BasicLayout>
      <Header />
      <main className="flex-grow pb-8 w-full">
        <div className="flex flex-row lg:space-x-5 px-6 lg:px-12 justify-center">
          <div className="flex flex-col space-y-10">
            {children}
            <div className="block lg:hidden">
              <TagBox />
            </div>
          </div>
          <aside className='hidden lg:block'>
            <div className="lg:sticky lg:top-6">
              <TagBox />
            </div>
          </aside>
        </div>
      </main>
      <Footer />
      <BackToTopButton />
    </BasicLayout>
  )
}

export const PostLayout = ({ children, toc }) => {
  return (
    <BasicLayout>
      <Header />
      <main className="flex-grow pb-8 w-full">
        <div className="flex flex-row space-x-5 px-5 justify-center">
          {children}
          {toc && toc.items && <Toc toc={toc} />}
        </div>
      </main>
      <Footer />
      <BackToTopButton />
    </BasicLayout>
  )
}

export const BasicLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-slate-100">
      {children}
    </div>
  )
}