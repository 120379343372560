import React from "react"

const Footer = () => {
  return (
    <footer className="py-2 px-16 bg-white text-gray-500 text-sm">
      <div className="flex flex-col md:flex-row items-center md:justify-between py-1">
        <div className="flex">
          <p>© 2020 - 2021</p>
        </div>
        <div className="flex flex-row space-x-2">
          <p>
            Powered by{" "}
            <a className="font-semibold hover:text-sky-600" href="https://gatsbyjs.com">
              Gatsby.js
          </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer