import React from "react"

function scrollSmoothTo(elementId) {
  let element = document.getElementById(elementId)
  if (element !== null) {
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }
}

const TocEnrtry = ({ items, depth = 0 }) => {
  return items.map(item => {
    return (
      <ul>
        <li className="text-slate-500 hover:text-sky-500 truncate" style={{paddingLeft: `${depth}rem`}}>
          <button className="focus:outline-none" onClick={() => scrollSmoothTo(item.url.substr(1))}>
            <p className="text-sm py-1 pl-2">
              {item.title}
            </p>
          </button>
        </li>
        {item.items && depth < 2 && (
          <TocEnrtry items={item.items} depth={depth + 1} />
        )}
      </ul>
    )
  })
}

const Toc = props => {
  const { toc } = props

  return (
    <aside className="hidden lg:block p-6 w-64">
      <div className="sticky top-6 overflow-auto">
        <p className="uppercase text-xl font-medium mb-4">
          文章目录
        </p>
        {toc && toc.items && <TocEnrtry items={toc.items} />}
      </div>
    </aside>
  )
}

export default Toc