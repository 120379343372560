import React from "react"
import { Link as GatsbyLink } from "gatsby"

const MenuItem = ({ children, to }) => {
  return (
    <GatsbyLink to={to}>
      <button className="p-1 hover:opacity-75">
        <p className="font-bold">{children}</p>
      </button>
    </GatsbyLink>
  )
}


const Header = () => {

  return (
    <nav className="flex flex-wrap bg-white mb-8 py-1 px-8 justify-between">
      <div className="flex md:my-auto w-full md:w-auto">
        <div className="mx-auto flex justify-center md:items-end">
          <GatsbyLink to="/">
            <button className="hover:opacity-75 p-1">
              <p className="text-lg font-bold uppercase">
                kottory.me
            </p>
            </button>
          </GatsbyLink>
        </div>
      </div>

      <div className="flex md:my-auto w-full md:w-auto">
        <div className="mx-auto flex justify-center md:items-end space-x-4">
          <MenuItem to="/">首页</MenuItem>
          {/* <MenuItem to="/tags">标签</MenuItem> */}
          <MenuItem to="/friends">友链</MenuItem>
          <MenuItem to="/about">关于</MenuItem>
        </div>
      </div>
    </nav>
  )
}

export default Header