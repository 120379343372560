import React from "react"
import { HiChevronUp } from "react-icons/hi"

export const BackToTopButton = () => (
  <div className="fixed bottom-8 right-8 z-10 shadow-lg bg-white rounded-full w-10 h-10">
    <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="focus:outline-none">
      <HiChevronUp className="w-10 h-10" />
    </button>
  </div>
)

const Anchor = ({ children, href }) => (
  <a className="hover:underline after:content-['_↗']" href={href} >{children}</a>
)

export const components = {
  a: Anchor,
}