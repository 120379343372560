import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const TagItem = ({ children }) => {

  return (
    <span className='px-1 text-slate-600 hover:text-slate-400'>
      {children}
    </span>
  )
}

const TagBox = () => {
  const { allMdx: { group } } = useStaticQuery(graphql`
    {
      allMdx(filter: {slug: {regex: "/posts/"}, frontmatter: {draft: {ne: true}}}) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `)
  console.log(group)
  return (<div className="card-aside md:max-w-screen-md lg:w-64">
    <h2 className="text-gray-600 pb-4">所有标签</h2>
    <div className="flex flex-row flex-wrap">
      {group.map(({ fieldValue }) => (
        <Link to={`/tags/${fieldValue}`}>
          <TagItem >{'#' + fieldValue}</TagItem>
        </Link>
      ))}
    </div>
  </div>)
}

export default TagBox